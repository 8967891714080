import React from "react";
import useFetchCareers from "../CareersPage.hooks";
import { Row, Container, Col } from "react-bootstrap";
import * as S from "./Content.styles";
import * as SH from "@shared";
import ReactHtmlParser from "react-html-parser";
export const Content = () => {
  const { data } = useFetchCareers();

  return (
    <SH.Wrapper.Primary>
      <Container>
        <Row className="justify-content-center">
          <Col xs="12" xl="10">
            {!!data.allStrapiCareersPage.edges[0].node.whyJoinCoders &&
              data.allStrapiCareersPage.edges[0].node.whyJoinCoders.map(
                ({ id, description, image, title }) => (
                  <S.WrapperBox key={id}>
                    <Row className="h-100 justify-content-between row-box">
                      <Col lg="5">
                        <SH.Text.Header2 className="pb-5">
                          {title}
                        </SH.Text.Header2>
                        <SH.Text.MarkdownTextBody>
                          {ReactHtmlParser(description)}
                        </SH.Text.MarkdownTextBody>
                      </Col>
                      <Col
                        className="d-flex justify-content-center align-self-end align-self-lg-center"
                        lg="6"
                      >
                        <S.WrapperImage heightWrapperImage={heightWrapperImage}>
                          <S.Image
                            src={image?.localFile.publicUrl}
                            alt={image?.alternativeText}
                          />
                        </S.WrapperImage>
                      </Col>
                    </Row>
                  </S.WrapperBox>
                )
              )}
          </Col>
        </Row>
      </Container>
    </SH.Wrapper.Primary>
  );
};

const heightWrapperImage = {
  desktop: 505,
  mobile: 350,
};
