import { useStaticQuery, graphql } from "gatsby";

const useFetchCarrers = () => {
  const data: TData = useStaticQuery(graphql`
    {
      allStrapiVacancies(sort: { fields: published_at }) {
        edges {
          node {
            title
            id
            slug
          }
        }
      }
      strapiCareersPage {
        isVacanciesOpen
      }
    }
  `);

  return { data };
};

export default useFetchCarrers;

export type TData = {
  allStrapiVacancies: {
    edges: {
      node: {
        id: string;
        title: string;
        slug: string;
      };
    }[];
  };
  strapiCareersPage: {
    isVacanciesOpen: boolean;
  };
};
