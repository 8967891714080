import styled from "styled-components";
import * as SH from "@shared";
import * as theme from "@theme";

export const HeroSection = styled(SH.Wrapper.HeroSection)`
  min-height: 75vh;
  width: 100%;
  background-size: cover;
  padding: 175px 0;
  background-color: ${theme.colors.white};
`;
