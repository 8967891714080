import styled, { css } from "styled-components";
import * as theme from "@theme";
import * as SH from "@shared";

export const WrapperBox = styled(SH.Wrapper.Box)`
  min-height: min-content;
  height: 795px;
  &:nth-of-type(even) {
    background-color: ${theme.colors.white};
  }
  @media ${theme.breakpoints.desktop} {
    height: 505px;
  }
`;

export const Image = styled(SH.Image)`
  object-fit: contain;
`;

export const WrapperImage = styled.div<{
  heightWrapperImage: { desktop: number; mobile: number };
}>`
  img {
    max-height: ${({ heightWrapperImage }) =>
      heightWrapperImage ? `${heightWrapperImage.mobile}px` : `auto`};
    @media ${theme.breakpoints.desktop} {
      max-height: ${({ heightWrapperImage }) =>
        heightWrapperImage ? `${heightWrapperImage.desktop}px` : `auto`};
    }
  }
`;
