import { useStaticQuery, graphql } from "gatsby";

const useFetchCarrers = () => {
  const data: TData = useStaticQuery(graphql`
    {
      allStrapiCareersPage(sort: { fields: id }) {
        edges {
          node {
            benefit {
              id
              title
              image {
                localFile {
                  publicUrl
                }
                alternativeText
              }
            }
            whyJoinCoders {
              description
              id
              title
              image {
                localFile {
                  publicUrl
                }
                alternativeText
              }
            }
          }
        }
      }
    }
  `);

  return { data };
};

export default useFetchCarrers;

type TData = {
  allStrapiCareersPage: {
    edges: {
      node: {
        whyJoinCoders: {
          description: string;
          id: number;
          title: string;
          image: {
            localFile: {
              publicUrl: string;
            };
            alternativeText: string;
          };
        }[];
        benefit: {
          id: number;
          title: null;
          image: {
            localFile: {
              publicUrl: string;
            };
            alternativeText: string;
          };
        }[];
      };
    }[];
  };
};
