import React from "react";
import { graphql } from "gatsby";
import Layout from "@components/Layout";
import { HeroSection } from "@components/404PageCareers";
import { TPage404Careers } from "@types";

const NotFoundPageCareers = ({ data }: TPage404Careers) => {
  const { hero, image, title } = data.strapiPage404Careers;

  return (
    <Layout seo={seo}>
      <HeroSection
        image={image}
        bgHero={{ bgMobile: hero?.bgMobile, bgDesktop: hero?.bgDesktop }}
        title={title}
      />
    </Layout>
  );
};
export default NotFoundPageCareers;

export const query = graphql`
  {
    strapiPage404Careers {
      image {
        localFile {
          publicUrl
        }
      }
      title
      hero {
        bgMobile {
          localFile {
            publicUrl
          }
        }
        bgDesktop {
          localFile {
            publicUrl
          }
        }
      }
    }
  }
`;
const seo = {
  metaTitle: "Offer not found",
  metaDescription: "Offer not found 404",
};
