import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import useFetchVacancies from "./CurrenVacancies.hooks";
import * as SH from "@shared";
import * as S from "./CurrentVacancies.styles";

export const CurrentVacancies404 = React.memo<TProps>(
  ({ selectorId = "current-vacancies" }) => {
    const { data } = useFetchVacancies();
    const isVacanciesOpen = data.strapiCareersPage.isVacanciesOpen;

    return (
      <Container>
        <Row className="justify-content-center" id={selectorId}>
          <Col lg="10">
            <SH.Text.Header2>current vacancies</SH.Text.Header2>
          </Col>
          {isVacanciesOpen &&
            data.allStrapiVacancies.edges.map(
              ({ node: { title, id, slug } }) =>
                title !== "null" && (
                  <S.VacanciesColLink
                    key={id}
                    to={`/careers/${slug}`}
                    className="col-lg-10 "
                  >
                    {title}
                  </S.VacanciesColLink>
                )
            )}
          {!isVacanciesOpen && (
            <S.VacanciesCol className="col-lg-10 d-flex justify-content-center flex-column">
              <SH.Text.Header3 className="text-center mb-0">
                we do not have any openings right now{" "}
              </SH.Text.Header3>
            </S.VacanciesCol>
          )}
        </Row>
      </Container>
    );
  }
);

type TProps = {
  selectorId?: string;
};
