import styled from "styled-components";
import * as theme from "@theme";
import * as SH from "@shared";
import { Row, Col } from "react-bootstrap";

export const BenefitBox = styled.div`
  width: 100%;
  width: 50vw;
  height: 50vw;
  max-width: 165px;
  max-height: 165px;
  background: ${theme.colors.white};
  border-radius: 12px;
  margin: 10px 0;
  padding: 25px;

  @media ${theme.breakpoints.desktop} {
    padding: 54px 62px 54px 54px;
    max-width: 295px;
    max-height: 295px;
  }
`;

export const BenefitRow = styled(Row)`
  margin: 0 -10px;
`;

export const BenefitCol = styled(Col)`
  padding: 0 10px;
`;

export const Image = styled(SH.Image)`
  height: 50px;
  width: 50px;
  @media ${theme.breakpoints.desktop} {
    height: 85px;
    width: 85px;
  }
`;
