import React from "react";
import * as SH from "@shared";
import { Row, Container, Col } from "react-bootstrap";
import * as S from "./Error404Careers.styles";
import ReactHtmlParser from "react-html-parser";
import { useFetchDefaultHeroImg } from "@hooks";
import * as C from "../CareersPage";
export const HeroSection = ({
  bgHero = {},
  image,
  title,
}: THeroSection404Props) => {
  const { data } = useFetchDefaultHeroImg();
  const { defaultHero } = data.strapiGlobal;
  const fullHeroImg = { ...defaultHero, ...bgHero };

  return (
    <S.HeroSection
      bgMobile={fullHeroImg.bgMobile.localFile.publicUrl}
      bgDesktop={fullHeroImg.bgDesktop.localFile.publicUrl}
      className="d-flex flex-column justify-content-center align-items-center"
    >
      {image && (
        <SH.Image src={image.localFile.publicUrl} heightImg={height}></SH.Image>
      )}
      <SH.Text.TextBody className="py-5">{title}</SH.Text.TextBody>
      <C.CurrentVacancies404 />
    </S.HeroSection>
  );
};

const height = {
  desktop: 194,
  mobile: 150,
};

export type THeroSection404Props = {
  title: string;
  bgHero?: {};
  image: {
    localFile: {
      publicUrl: string;
    };
  };
};
