import styled, { css } from "styled-components";
import * as theme from "@theme";
import * as SH from "@shared";
import { Link } from "gatsby";
import { Col, Row } from "react-bootstrap";
import Arrow from "./arrow.svg";

const VacanciesColCss = css`
  background: ${theme.colors.whitegray};
  width: 100%;
  display: inline-block;
  width: 100%;
  padding: 0 15px;
  color: inherit;
  font-weight: ${theme.fonts.fontWeight.medium500};
  line-height: 4.8rem !important;
  position: relative;
  margin-bottom: 23px;

  &:hover {
    color: inherit;
    text-decoration: none;
  }

  @media ${theme.breakpoints.desktop} {
    padding: 0 24px;
  }
`;

export const VacanciesColLink = styled(Link)`
  ${SH.Text.Vacancies};
  ${VacanciesColCss};
  height: 48px;
  &:first-of-type {
    margin-top: 43px;
  }
  &::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 48px;
    right: 20px;
    background: url(${Arrow}) no-repeat center center;
  }
`;

export const VacanciesCol = styled.div`
  ${VacanciesColCss};
  text-align: center;
  height: 85px;
  margin-top: 53px;
`;
