import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import useFetchCareers from "../CareersPage.hooks";
import * as SH from "@shared";
import * as S from "./PerksAndBenefit.styles";

export const PerksAndBenefits = () => {
  const { data } = useFetchCareers();

  return (
    <SH.Wrapper.Primary>
      <Container>
        <SH.Wrapper.Box className="mb-0" colorComponent="whitegray">
          <S.BenefitRow className="justify-content-center">
            <S.BenefitCol xs="12">
              <SH.Text.Header2 className="text-left text-lg-center pb-4">
                perks & benefits
              </SH.Text.Header2>
            </S.BenefitCol>
            {!!data.allStrapiCareersPage.edges[0].node.benefit &&
              data.allStrapiCareersPage.edges[0].node.benefit.map(
                ({ image, title, id }) => (
                  <S.BenefitCol
                    className="d-flex justify-content-center align-self-lg-center"
                    xs="6"
                    lg="3"
                    key={id}
                  >
                    <S.BenefitBox>
                      <S.Image
                        src={image?.localFile.publicUrl}
                        alt={image?.alternativeText}
                      />
                      <SH.Text.SubTitle1 className="pt-3">
                        {title}
                      </SH.Text.SubTitle1>
                    </S.BenefitBox>
                  </S.BenefitCol>
                )
              )}
          </S.BenefitRow>
        </SH.Wrapper.Box>
      </Container>
    </SH.Wrapper.Primary>
  );
};
